import { render, staticRenderFns } from "./Epitelium.vue?vue&type=template&id=832738fc&scoped=true&"
import script from "./Epitelium.vue?vue&type=script&lang=js&"
export * from "./Epitelium.vue?vue&type=script&lang=js&"
import style0 from "./Epitelium.vue?vue&type=style&index=0&id=832738fc&prod&lang=scss&scoped=true&"
import style1 from "./Epitelium.vue?vue&type=style&index=1&id=832738fc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832738fc",
  null
  
)

export default component.exports
<template>
  <div class="epitelium">
    <div class="epitelium__banner">
      <div class="content">
        <div class="epitelium__banner-text">
          <div class="epitelium__banner-subtitle">
            Открыто дело «Тяжелая бронхиальная астма»
          </div>
          <div class="epitelium__banner-title">
            Приказы к развитию воспаления отдает
            <span style="color: #c4d600">эпителий<sup style="color: inherit;">1-8</sup>!</span
            >
          </div>
        </div>
      </div>

      <picture>
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner_mob.jpg`"
          media="(max-width: 767px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner_tab.jpg`"
          media="(max-width: 1220px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner.jpg`"
          media="(max-width: 1919px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner_1920.jpg`"
          media="(max-width: 2559px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner_2560.jpg`"
          media="(max-width: 3839px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner_3840.jpg`"
          media="(min-width: 3840px)"
        />
        <img
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/banner.jpg`"
          class="epitelium__banner-img"
        />
      </picture>
    </div>
    <div
      class="content epitelium__tabs pb-0"
      style="background-color: #000"
      ref="tabs"
    >
      <div class="epitelium__tabs">
        <div
          class="epitelium__tab"
          @click="toLink('link4')"
          :class="{ epitelium__tab_active: activeTab === 3 }"
        >
          Главная
        </div>
        <div
          class="epitelium__tab"
          @click="toLink('link3')"
          :class="{ epitelium__tab_active: activeTab === 2 }"
        >
          Место преступления
        </div>
        <div
          class="epitelium__tab"
          :class="{ epitelium__tab_active: activeTab === 0 }"
          @click="toLink('link1')"
        >
          Список подозреваемых
        </div>
        <router-link
        :to="{name: 'EpiteliumGame', query: $route.query}"
          class="epitelium__tab"
        >
          Хронология событий
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="epitelium__first-block first-block">
        <div class="first-block__image-container">
          <img
            class="first-block__image"
            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/1.jpg`"
          />
        </div>
        <div class="first-block__text-container">
          <div class="first-block__caption" id="link4">
            Дело «Тяжелая бронхиальная астма»
          </div>
          <div class="first-block__description">
            Тяжелая астма – это крайне гетерогенное и динамичное заболевание,
            спровоцировано разного рода повреждающими агентами и подразумевает
            множество воспалительных факторов, различных биомаркеров, а также
            широкий спектр фенотипов и эндотипов<sup style="color: inherit"
              >1-3,9</sup
            >.<br /><br />
            Предшествующие исследования данной гетерогенной патологии были
            сосредоточены на роли специфических медиаторов воспаления в
            дыхательных путях, таких как иммуноглобулин Е, IL-4, IL-5, IL-13 и
            эозинофилы<sup style="color: inherit">1-3</sup>.
          </div>
        </div>
      </div>
      <div class="epitelium__blockquote">
        Однако было установлено, что это воспаление часто начинается на
        <br />вершине воспалительного каскада с участием ключевых
        <br />эпителиальных цитокинов, таких как TSLP, IL-33, and IL-25<sup
          style="color: inherit"
          >1,6,10</sup
        >.
      </div>
      <div
        id="link3"
        class="epitelium__first-block first-block first-block_reverse"
      >
        <div class="first-block__text-container">
          <div class="first-block__caption">Место преступления</div>
          <div class="first-block__description">
            Эпителий дыхательных путей зачастую является тем самым источником
            воспаления и центральным фактором в патогенезе астмы<sup
              style="color: inherit"
              >1-3</sup
            >.
            Эпителий - это защитный барьер, медиатор иммунитета потенциальная
            отправная точка для ремоделирования дыхательных путей<sup
              style="color: inherit"
              >2,3,11-13</sup
            >.
            Именно он первый встречается линией с вирусами, аллергенами,
            поллютантами, бактериями и другими внешними воздействиями<sup
              style="color: inherit"
              >1,6,10</sup
            >.
          </div>
        </div>
        <div class="first-block__image-container">
          <img
            class="first-block__image"
            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/2.jpg`"
          />
        </div>
      </div>

      <div class="epitelium__video-container">
        <video
          class="epitelium__video"
          loop
          muted
          autoplay
          playsinline 
          preload="metadata"
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/content/epitelium/back-min.mp4`"
        ></video>
      
          <img
            class="epitelium__img"
            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/3.jpg`"
          />
       

        <div class="epitelium__video-caption">
          Утолщение гладкой мускулатуры дыхательных путей, гиперреактивность
          <br />
          и потенциальное ремоделирование
          <sup style="color: inherit">18, 19, 24, 25</sup>
        </div>
        <div class="epitelium__video-description">
          Высвобождение эпителиальных цитокинов способствует <br>патологическому воспалению, которое может вызвать усиление симптомов и обострение астмы <sup style="color: inherit">1-4</sup>.
        </div>
        <div class="epitelium__video-tip">
          * Механизмы, лежащие в основе неэозинофильного воспаления, требуют
          дальнейшего изучения
        </div>
      </div>

      <div class="epitelium__additional">
        <div class="epitelium__additional-caption">
          Имеется дополнительная информация!
        </div>
        <div class="epitelium__additional-description">
          Оцените полную картину событий, чтобы познакомиться с воспалительным
          каскадом и узнать больше о роли ключевых эпителиальных цитокинов
          (TSLP, IL-33 и IL-25) в развитии воспаления при тяжелой бронхиальной
          астме <sup style="color: inherit">1-3,5-7,10</sup>

          <router-link
            :to="{ name: 'EpiteliumGame', query: $route.query }"
            class="button mt-4 px-4 py-3 epitelium__additional-button"
          >
            Оценить
          </router-link>
        </div>
      </div>

      <div class="epitelium__crime-list" id="link1">
        <div class="epitelium__crime-list-caption">
          Изучить список подозреваемых
        </div>
        <img
          class="epitelium__img"
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/4.jpg`"
        />
        <!-- <div class="epitelium__crime-list-description">
          <span
            >Узнайте, каким образом ключевые эпителиальные цитокины (TSLP, IL-33
            и IL-25) <br />участвуют в развитии воспаления при тяжелой
            астме.<sup style="color: inherit">1-3,10</sup></span
          >
          <router-link
            :to="{name: 'EpiteliumQuiz', query: $route.query}"
            class="
              button button_blue
              mt-4
              d-inline-flex
              px-4
              py-3
              epitelium__crime-list-button
            "
          >
            Узнать больше
          </router-link>
        </div> -->
      </div>

      <div class="epitelium__tba">
        <div class="epitelium__tba-caption">
          Детали допроса подозреваемых в деле <br />«Тяжелая бронхиальная астма»
        </div>
        
          <div class="epitelium__tba-cards">
            <div class="epitelium__tba-card">
              <div class="epitelium__tba-card-image-container">
                <img
                  class="epitelium__tba-card-image"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/card1.png`"
                />
              </div>
              <div class="epitelium__tba-card-text">
                «Я знаю, что меня в мокроте и в крови очень много, и да, мои
                белки повсюду<sup style="color: inherit">1,2</sup>. Но я точно
                не единственный, кто причастен!»
              </div>
            </div>
            <div class="epitelium__tba-card">
              <div class="epitelium__tba-card-image-container">
                <img
                  class="epitelium__tba-card-image"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/card2.png`"
                />
              </div>
              <div class="epitelium__tba-card-text">
                «Виноваты те, кто находится на вершине воспалительного
                каскада»<sup style="color: inherit">1,2,5-10</sup>
              </div>
            </div>
            <div class="epitelium__tba-card">
              <div class="epitelium__tba-card-image-container">
                <img
                  class="epitelium__tba-card-image"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/card3.png`"
                />
              </div>
              <div class="epitelium__tba-card-text">
                «Именно эпителиальные цитокины вызвали сильное выраженное
                воспаление при тяжелой бронхиальной астме. Есть и другие
                участники, которые получают приказы, а всем этим руководит
                эпителий!»<sup style="color: inherit">1,2,5-10</sup>
              </div>
            </div>
          </div>

        <div class="epitelium__tba-subcaption">
          «Приказы к развитию воспаления отдает эпителий»
          <sup style="color: inherit">1-8</sup>
        </div>
      </div>

      <div class="epitelium__bottom-banner">
        Фокус внимания на
        <span style="color: #c4d600">эпителиальных цитокинах</span>, стоящих на
        вершине<br />
        воспалительного каскада, таких как TSLP, IL-33 и IL-25, позволяет нам по
        новому <br />взглянуть на тяжелую бронхиальную астму  <sup style="color: inherit">1,5,10</sup>
      </div>

      <div class="epitelium__main-suspect">
        <div class="epitelium__main-suspect-caption">Главные подозреваемые</div>
        <div class="epitelium__main-suspect-description">
          В ответ на воздействие вирусов, аллергенов, загрязняющих веществ,
          бактерий, физических травм, механических повреждений и других внешних
          воздействий эпителий дыхательных путей выделяет цитокины, такие как
          TSLP, IL-33 и IL-25. Затем эти эпителиальные цитокины инициируют
          несколько нижестоящих воспалительных путей<sup style="color: inherit"
            >1,5,10</sup
          >.
          <br />
          <br />
          Избыточная экспрессия IL-33 и TSLP эпителием может способствовать
          гиперактивному иммунному ответу и воспалению, связанным с астмой<sup
            style="color: inherit"
            >5,10</sup
          >,
          которые в клинических исследованиях коррелировали с усилением тяжести
          астмы и снижением функции легких<sup style="color: inherit">26</sup>.
        </div>
        <div class="epitelium__main-suspect-container">
          <div class="epitelium__main-suspect-container-text">
            <div class="epitelium__main-suspect-container-title">
              Ввиду их нахождения на вершине воспалительного каскада,
            </div>
            <div class="epitelium__main-suspect-container-description">
              • TSLP может играть роль в аллергическом воспалении, эозинофильном
              воспалении и структурных клеточных эффектах, участвующих в T2 и
              не-T2 воспалении<sup style="color: inherit">1-3,5-7</sup>.
              <br />
              <br />
              • IL-25 и IL-33 посредством активации ILC2 могут играть роль в
              аллергическом воспалении, эозинофильном воспалении, а также
              неаллергическом эозинофильном воспалении<sup
                style="color: inherit"
                >1-3,5-7,18</sup
              >.
              <br />
              <br />
              Таким образом, у некоторых пациентов с тяжелой астмой, независимо
              от типа воспаления, эпителиальные цитокины могут стимулировать
              иммунный ответ с чрезмерной реакцией на различные повреждения
              эпителия, вызывающие воспаление, что может привести к появлению
              стойких симптомов астмы и частым ее обострениям<sup
                style="color: inherit"
                >1,5</sup
              >.
            </div>
          </div>
          <div class="epitelium__main-suspect-container-image">
            <img
              class="epitelium__main-suspect-container-img"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/5.jpg`"
            />
          </div>
        </div>
      </div>

      <div class="epitelium__main-video">
        <div class="epitelium__main-video-caption">
          Посмотреть видео о механизме развития заболевания
        </div>
        <video
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/epitelium/main-video.mp4`"
          poster="t"
          controls="true"
          class="epitelium__main-video-video"
        />
      </div>

      <InfoDrop :arrowColor="'#fff'" :item="shortList" class="mb-12" />
      <InfoDrop :arrowColor="'#fff'" :item="sources" />
    </div>
  </div>
</template>

<script>
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
import { mapGetters } from "vuex";
export default {
  name: "Epitelium",
  components: {
    InfoDrop,
  },
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    ...mapGetters(["user"]),
    shortList() {
      return {
        title: "<span style='color: #fff'>Список сокращений</span>",
        content: `
        <span style='color: #fff'>
        TSLP – тимусный стромальный лимфопоэтин; 
        <br>
        <br>
        Il – интерлейкин; 
        <br>
        <br>
        Ig – иммуноглобуллин; 
        <br>
        <br>
        ТН2 - Т2 – хелперы.
        </span>
        `,
      };
    },
    sources() {
      return {
        title: "<span style='color: #fff'>Источники</span>",
        content: `
        <ol style="color: #fff">
        <li>Corren J. J Allergy Clin Immunol Pract. 2019;7(5):1394-1403. </li>
        <li>Lambrecht BN, Hammad H, Fahy JV. Immunity. 2019;50(4):975-991. </li>
        <li>Lambrecht BN, Hammad H. Nat Immunol. 2015;16(1):45-56. </li>
        <li>Ying S, O'Connor B, Ratoff J, et al. J Immunol. 2005;174(12):8183-8190. </li>
        <li>Liang Y, Yu B, Chen J, et al. J Allergy Clin Immunol. 2019;144(4):1025-1035. </li>
        <li>Li Y, Wang W, Lv Z, et al. J Immunol. 2018;200(7):2253-2262. </li>
        <li>Tanaka J, Watanabe N, Kido M, et al. Clin Exp Allergy. 2009;39(1):89-100. </li>
        <li>Chesné J, Braza F, Mahay G, et al. Am J Respir Crit Care Med. 2014;190(10):1094-1101. </li>
        <li>Menzies-Gow A, Wechsler ME, Brightling CE. Respir Res. 2020;21(1):268:1-7. </li>
        <li>Sy CB, Siracusa MC. Front Physiol. 2016;7(214):1-7. </li>
        <li> Bartemes KR, Kita H. Clin Immunol. 2012;(3):222-235. </li>
        <li>1Redhu NS, Gounni AS. Clin Exp Allergy. 2012;42(7):994-1005. </li>
        <li>Roan F, et al. J Clin Invest. 2019;12(4):1441-1451. </li>
        <li>Brusselle G, Bracke K. Ann Am Thorac Soc. 2014;11:S322-S328. </li>
        <li>Pelaia G, et al. Nat Rev Drug Discov. 2012;11:958-997. </li>
        <li>Pasha MA, et al. Allergy Asthma Proc. 2019;40(3):138-145. </li>
        <li>Brusselle G, et al. Nat Med. 2013;19:977-979. </li>
        <li>Gauvreau GM, et al. Expert Opinion on Therapeutic Targets. 2020;24(8):777-792. </li>
        <li>Wu J et al. Cell Biochem Funct. 2013;31:496–503. </li>
        <li>Divekar R, Kita H. Curr Opin Allergy Clin Immunol. 2015 Feb;15(1):98-103. </li>
        <li>Hams E, et al. Proc Natl Acad Sci U S A. 2014 Jan 7;111(1):367-372. </li>
        <li>An G, et al. Immunology. 2018 Feb 18;154(4):637–650. </li>
        <li>An G, et al. Respirology. 2020 Jun;25(6):603-612. </li>
        <li>Kaur D, et al. Chest. 2012;142:76-85. </li>
        <li>Cao L et al. Exp Lung Res. 2018;44:288–301.</li> 
        <li>Shikotra A, Choy DF, Ohri SM, et al. J Allergy Clin Immunol . 129(1):104-111.e9.</li>
        </ol>
        `,
      };
    },
  },
  methods: {
    toLink(link) {
      this.activeTab = parseInt(link.replace(/^\D+/g, "")) - 1;
      const section = document.querySelector("#" + link);

      // определяем отступ от верхнего края всей страницы (не области просмотра)
      const position = Math.round(section.offsetTop) + window.innerHeight - 100;

      // прокручиваем область просмотра до нужной позиции
      scrollTo({
        top: position,
        // плавно
        behavior: "smooth",
      });
    },
    observer() {
      const vm = this;
      const createObserver = () => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const link = entry.target.id;
                vm.activeTab = parseInt(link.replace(/^\D+/g, "")) - 1;
              }
            });
          },
          {
            // процент пересечения целевого элемента с областью просмотра
            // 10%
            threshold: 0.3,
          }
        );

        // находим все секции
        const sections = document.querySelectorAll("div[id^='link']");
        // начинаем за ними наблюдать
        sections.forEach((section) => observer.observe(section));
      };

      createObserver();
    },
  },
  mounted() {
    this.observer();
    if(this.user && !Object.keys(this.user).length) {
      this.$router.push({...this.$route, query: {...this.$route.query, access: "589fab7cd12b743cbc766e6a7d950fd4"}})
    }
  },
  watch: {
    activeTab() {
      setTimeout(() => {
        const position = Math.round(
          this.$refs.tabs.querySelector(".epitelium__tab_active").offsetLeft
        );
        // прокручиваем область просмотра до нужной позиции
        this.$refs.tabs.scrollTo({
          top: 0,
          left: position,
          // плавно
          behavior: "smooth",
        });
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.epitelium {
  &__banner {
    position: relative;
    height: 684px;
    padding-top: 77px;

    @media screen and (max-width: 767px) {
      height: 455px;
      padding-top: 27px;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-text {
      position: relative;
      z-index: 2;
    }

    &-subtitle {
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      @media screen and (max-width: 1220px) {
        text-align: left;
        margin-bottom: 16px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    &-title {
      color: #fff;
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 141.176% */

      @media screen and (max-width: 1220px) {
        text-align: left;
        font-size: 42px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }
  }

  &__tabs {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: sticky;
    top: 0;
    border-top: 1px solid #1e1e1e;
    background-color: #000;
    padding-bottom: 40px;

    @media screen and (max-width: 1220px) {
      top: 0 !important;
    }

    @media screen and (max-width: 767px) {
      & > .epitelium__tabs {
        overflow-x: visible;
      }
      overflow-x: auto;
    }
  }

  &__tab {
    padding-top: 16px;

    margin-right: 32px;
    border-top: 4px solid transparent;
    transition: 0.3s;
    color: #b2b4b4;
    text-align: center;

    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;

    @media screen and (max-width: 1220px) {
      font-weight: 400;
      font-size: 16px;
      margin-right: 20px;
    }

    @media screen and (max-width: 767px) {
      white-space: nowrap;
    }

    &:last-child {
      margin-right: 0;
    }

    &_active {
      border-color: #8dc53e;
    }
  }

  &__blockquote {
    padding: 48px;
    color: #fafce9;
    text-align: center;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;

    @media screen and (max-width: 1220px) {
      padding: 24px 0;
      & br {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 21px;
    }
  }

  &__video {
    width: 100%;
    height: auto;

    &-caption {
      margin-top: 32px;
      margin-bottom: 24px;
      color: #fafce9;
      text-align: center;
      /* Desktop/Heading/H2 */
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px; /* 126.087% */
    }

    &-description {
      margin-bottom: 24px;
      color: #d2d2d2;
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    &-tip {
      color: #d2d2d2;
      text-align: center;
      /* Desktop/Label/S */
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;

    &-overflow {
      @media screen and (max-width: 1220px) {
        overflow-x: auto;
        margin: 0 -76px;
        padding-left: 76px;
      }

      @media screen and (max-width: 767px) {
        margin: 0 -16px;
        padding-left: 16px;
      }

      & img {
        @media screen and (max-width: 1220px) {
          width: 120vw;
        }
        @media screen and (max-width: 767px) {
          width: 200vw;
        }
      }
    }
  }

  &__additional {
    margin-top: 48px;
    padding: 48px;
    border: 1px solid #50b5dd;
    background-color: #111419;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      padding: 32px 24px;
    }

    &-caption {
      color: #fafce9;
      /* Desktop/Label/Extra */
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */
      border-right: 1px solid #1e1e1e;
      padding-right: 103px;
      flex-shrink: 0;
      width: 45%;
      margin-right: 103px;

      @media screen and (max-width: 1220px) {
        font-size: 23px;
        font-weight: 400;
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
        margin-bottom: 32px;
        width: auto;
      }
    }

    &-description {
      color: #d2d2d2;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    &-button {
      background-color: #8dc53e;
      color: #fff;
      width: fit-content;
    }
  }

  &__crime-list {
    margin-top: 48px;
    & .epitelium__img {
      max-width: 50%;
      margin: 0 auto;

      @media screen and (max-width: 1220px) {
        max-width: none;
      }
    }
    &-caption {
      margin-bottom: 100px;
      color: #fafce9;
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */

      @media screen and (max-width: 1220px) {
        font-weight: 400;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-description {
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #d2d2d2;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      @media screen and (max-width: 1220px) {
        flex-direction: column;
        align-items: flex-start;

        & br {
          display: none;
        }
      }
    }

    &-button {
      margin-left: auto;
      @media screen and (max-width: 1220px) {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }

  &__tba-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 16px 0;
    }
  }

  &__tba {
    margin-top: 48px;
  }

  &__tba-caption {
    margin-bottom: 40px;
    color: #fafce9;
    text-align: center;
    /* Desktop/Label/Extra */
    font-family: "Roboto Slab", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */

    @media screen and (max-width: 1220px) {
      font-weight: 400;
    }

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__tba-subcaption {
    margin-top: 40px;
    color: #c4d600;
    text-align: center;
    /* Desktop/Heading/H2 */
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 126.087% */

    @media screen and (max-width: 1220px) {
      margin-top: 24px;
    }
  }

  &__tba-card {
    height: 100%;
    background: linear-gradient(180deg, #111419 0%, rgba(17, 20, 25, 0) 100%);
    &:nth-child(2) {
      & .epitelium__tba-card-image {
        animation-delay: 1s;
      }
    }

    &-image-container {
      width: 100%;
    }

    &-image {
      width: 100%;
      animation: upDown 1s linear infinite alternate;
    }

    &-text {
      margin-top: 32px;
      color: #d2d2d2;
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      @media screen and (max-width: 767px) {
        padding: 0 16px;
      }
    }
  }

  &__bottom-banner {
    margin-top: 74px;
    padding: 48px;
    background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/epitelium/bottom-banner.jpg");
    background-size: cover;
    background-position: center;
    color: #fafce9;
    text-align: center;
    /* Desktop/Heading/H2 */
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 126.087% */

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
      padding: 24px;

      & br {
        display: none;
      }
    }
  }

  &__main-suspect {
    margin: 80px 0;

    &-caption {
      margin-bottom: 32px;
      color: #fafce9;
      text-align: center;
      /* Desktop/Label/Extra */
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */

      @media screen and (max-width: 1220px) {
        font-weight: 400;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-description {
      max-width: 80%;
      margin: 0 auto 48px;
      color: #d2d2d2;
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      @media screen and (max-width: 1220px) {
        max-width: none;
      }
    }

    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      @media screen and (max-width: 1220px) {
        flex-direction: column;
      }

      &-title {
        margin-bottom: 32px;
        color: #fafce9;
        /* Desktop/Heading/H3 */
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */

        @media screen and (max-width: 1220px) {
          font-size: 23px;
          line-height: 29px;
        }

        @media screen and (max-width: 767px) {
          font-size: 21px;
          line-height: 26px;
        }
      }

      &-description {
        color: #d2d2d2;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &-image {
        width: 420px;
        height: auto;
        margin-left: 100px;
        flex-shrink: 0;

        @media screen and (max-width: 1220px) {
          margin-left: 0;
          margin-top: 40px;
          width: 100%;
        }
      }

      &-img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__main-video {
    margin: 80px 0;

    &-caption {
      padding: 18px 32px;
      background-color: #8dc53e;
      color: #fafce9;
      /* Desktop/Label/L */
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }

    &-video {
      width: 100%;
      height: auto;
      background: transparent url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/epitelium/video-poster.jpg') no-repeat 0 0;
      background-size: cover;
      @media screen and (max-width: 1220px) {
        background: transparent url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/epitelium/video-poster_no-btn.jpg') no-repeat 0 0;
        background-size: cover;
      }
    }
  }
}

@keyframes upDown {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}

.first-block {
  margin: 40px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 1220px) {
    flex-direction: column-reverse;
  }

  &__image-container {
    width: 584px;
    height: auto;
    margin-right: 74px;
    flex-shrink: 0;

    @media screen and (max-width: 1220px) {
      margin-top: 32px;
      margin-right: 0;
      width: 100%;
    }
  }

  &_reverse {
    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
    .first-block {
      &__image-container {
        margin-right: 0;
        margin-left: 74px;

        @media screen and (max-width: 1220px) {
          margin-top: 32px;
          margin-left: 0;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  &__text-container {
    margin: auto 0;
  }

  &__caption {
    margin-bottom: 40px;
    color: #fafce9;
    /* Desktop/Label/Extra */
    font-family: "Roboto Slab", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */

    @media screen and (max-width: 1220px) {
      font-weight: 400;
      font-size: 23px;
    }
  }

  &__description {
    color: #d2d2d2;
    font-family: "Roboto Slab", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
</style>
<style lang="scss">
.epitelium {
  sup {
    vertical-align: super;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #830051;
    white-space: nowrap;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
}
</style>